var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sUQ3_R6zY1QO01WIG9iFy"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { Breadcrumbs, Dedupe, GlobalHandlers, init } from '@sentry/react'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const dsnURL = new URL(SENTRY_DSN)
const gitHash = process.env.NEXT_PUBLIC_GIT_HASH
const IgnoreThisError = 'IgnoreThisError'

const traceSampleRate = parseFloat(dsnURL.searchParams.get('trace_sample_rate') || '0.0')

const isUserRejected = (err) => {
  // provider user rejected error code
  return typeof err === 'object'
    ? 'code' in err && err.code === 4001
    : typeof err === 'string' && err.startsWith('Logger: ')
}

const filterIgnoredErrors = (event) => {
  const ignoredMessages = [IgnoreThisError]

  if (event?.message && ignoredMessages.some((msg) => event?.message?.includes(msg))) {
    return null
  }

  return event
}

const ENV = process.env.NODE_ENV

init({
  dsn: SENTRY_DSN,
  integrations: [
    new Breadcrumbs({
      console: ENV === 'production',
      fetch: false,
      xhr: false,
    }),
    new GlobalHandlers({
      onerror: false,
      onunhandledrejection: false,
    }),
    new Dedupe(),
  ],
  environment: ENV === 'production' ? 'production' : 'development',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: traceSampleRate,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event, hint) {
    const error = hint?.originalException

    if (error && isUserRejected(error)) {
      return null
    }

    const eventSent = filterIgnoredErrors(event)

    return eventSent
  },

  release: gitHash ? gitHash.substring(0, 8) : undefined,
  ignoreErrors: ['cancelled'],
})
